import React from "react";
import FormContact from "../../components/FormContact/FormContact";

const Contact = () => {
  return (
    <div>
      <FormContact />
    </div>
  );
};

export default Contact;
