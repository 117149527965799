import React from "react";

const Project = () => {
  return (
    <div>
      <p>SCREEN PROGETTI</p>
      <p>SCREEN PROGETTI</p>
      <p>SCREEN PROGETTI</p>
      <p>SCREEN PROGETTI</p>
      <p>SCREEN PROGETTI</p>
      <p>SCREEN PROGETTI</p>
      <p>SCREEN PROGETTI</p>
    </div>
  );
};

export default Project;
